import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share'
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin: 8px 0 0 4px;
  cursor: pointer;
`;

const Share = ({ className, url }) => {
  return (
    <Wrapper className={className}>
      <ButtonWrapper>
        <FacebookShareButton url={url}>
          <FacebookIcon size={24} />
        </FacebookShareButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={24} />
        </LinkedinShareButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <TwitterShareButton url={url}>
          <TwitterIcon size={24} />
        </TwitterShareButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Share;

import React from 'react';
import { Pile } from 'cards';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin-left: 20px;
  }
`;

const Melds = ({ className, melds, ...props }) => (
  <Wrapper className={className}>
    {melds && melds.map((meld) => (
      <Pile {...props} fanned="right" cards={meld} />
    ))}    
  </Wrapper>
);

export default Melds;
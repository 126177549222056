const values = {
  2: 5,
  3: 5,
  4: 5,
  5: 5,
  6: 5,
  7: 5,
  8: 5,
  9: 5,
  10: 10,
  J: 10,
  Q: 10,
  K: 10,
  A: 15,
};

export const score = (melds, cardsInHand) => {
  let score = 0;

  melds.forEach(meld => {
    meld.forEach(card => {
      score += values[card.rank];
    });
  });

  cardsInHand.forEach(card => {
    score -= values[card.rank];
  });
  
  return score;
};
import React from 'react';
import GraphQLProvider from './apollo/Provider';
import { Switch, Route } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import MainWrapper from './components/MainWrapper';
import MyRouter from './components/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import GamesRouter from '../components/GamesRouter';

const history = createMemoryHistory();

const App = () => (
  <GraphQLProvider>
    <Switch>
      <Route path="/games">
        <MainWrapper noSideBar>
          <GamesRouter />
        </MainWrapper>
      </Route>
      <Route>
        <MainWrapper>
          <MyRouter />
        </MainWrapper>
      </Route>
    </Switch>
  </GraphQLProvider>
);

export default App;

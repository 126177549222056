import React from 'react';
import moment from 'moment';
import OgTitle from './OgTitle';
import OgDesc from './OgDesc';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Body from './Body';
import Share from './Share';

const StyledShare = styled(Share)`
  float: right;
`;

const linkStyle = {
  color: '#000',
};

const Content = ({ body, isInList, publishedOn, title, url }) => (
  <div>
    <OgTitle message={title} />
    <OgDesc content={body.substr(0, body.indexOf(' ', 250))} />
    <StyledShare url={url} />
    <h1>{isInList ? (<Link to={url} style={linkStyle}>{title}</Link>) : title}</h1>
    {!!publishedOn && (
      <div className="mb-2">
        <small>
          Published On:
          {' '}
          {moment.unix(publishedOn).format('dddd, MMMM Do YYYY')}
        </small>
      </div>
    )}
    <Body copy={body} />
  </div>
);

export default Content;

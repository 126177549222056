import React from 'react';
import { PlayingCards, Pile } from 'cards';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import reducer, { initialState } from './reducer';
import AIPlayer, { SmallerPile } from './AIPlayer';
import Bet from './Bet';
import {
  next,
  fold,
  toggleAi,
  startTutorial,
} from './reducer/action-creators';
import {
  DEAL,
  NEXT_STEP,
} from './reducer/constants';
import { BET_TYPES } from './constants';
import TutorialText from './TutorialText';
import { Lose, Win, OverlayHole, OverlayHoleWrapper } from '../../components/Styled';

const StyledOverlayHoleWrapper = styled(OverlayHoleWrapper)`
  @media (max-width: 850px) {
    ${({ grid }) => grid && `grid-area: ${grid};`}
  }
`;

const Winner = styled(Win)`
  position: relative;
  color: black;
`;

const Loser = styled(Lose)`
  position: relative;
  color: red;
`;

const StyledWrapper = styled.div`
  min-width: 800px;
  min-height: 640px;
  max-width: 1024px;
  max-height: 90vh;
  display: grid;
  background: #477148;
  padding: 15px;
  grid-template-columns: 1fr 1.75fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  @media (max-width: 850px) {
    min-width: calc(99vw - 30px);
    grid-template: 'dealer bets' 'player bets' 'buttons buttons';
  }
`;

const PlayerPile = styled(Pile)`
  align-self: end;
  margin: 0 auto;
`;

const StyledAI = styled(AIPlayer)`
  @media (max-width: 850px) {
    display: none;
  }
`;

const Desktop = styled.div`
  @media (max-width: 850px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: block;
  }
`;

const DealerPile = styled(SmallerPile)`
  margin: 0 auto;
  @media (max-width: 850px) {
    grid-area: Dealer;
  }
`;

const Centered = styled.div`
  text-align: center;
`;

const TrueCentered = styled(OverlayHoleWrapper)`
  display: flex;
  justify-items: flex-center;
  flex-flow: column;
  > * {
    margin: 0 auto;
  }
  @media (max-width: 850px) {
    ${({ grid }) => grid && `grid-area: ${grid};`}
  }
`;

const FlexRight = styled(OverlayHoleWrapper)`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
`;

const DesktopOverlayHoldWrapper = styled(OverlayHoleWrapper)`
  @media (max-width: 850px) {
    display: none;
  }
`;

const DesktopPlayerPile = styled(PlayerPile)`
  @media (max-width: 850px) {
    display: none !important;
  }
`;

const MobilePlayerPile = styled(PlayerPile)`
  display: none !important;
  @media (max-width: 850px) {
    display: flex !important;
  }
  & > div:not(:last-child) {
    width: 60px; 
  }
`;

const ThreeCardPoker = () => {
  return (
    <PlayingCards
      reducer={reducer}
      initialState={initialState}
    >
      {(state, dispatch) => (
        <StyledWrapper>

          {/* Row 1 */}
          <Desktop>
            <button className="btn btn-info" onClick={() => dispatch(toggleAi())}>{state.showAiPlayers ? 'Hide' : 'Show'} AI Players</button>
          </Desktop>
          <OverlayHoleWrapper>
            <Mobile>Dealer</Mobile>
            <DealerPile
              width="75px"
              id="dealer"
              fanned="hand"
            />
            {state.tutorialStep === 'DEALER' && <OverlayHole />}
          </OverlayHoleWrapper>
          <DesktopOverlayHoldWrapper show={state.tutorialStep !== 'STOP' && state.tutorialStep !== 'WELCOME' && state.tutorialStep !== 'FINISH'}>
            <FlexRight>
              {state.step === 'START' && state.tutorialStep === 'STOP' &&
                <div>
                  <button className="btn btn-danger" onClick={() => dispatch(startTutorial())}>Start Tutorial</button>
                </div>
              }
              {state.tutorialStep !== 'STOP' && (
                <TutorialText step={state.tutorialStep} dispatch={dispatch} />
              )}
            </FlexRight>
            {state.tutorialStep === 'WELCOME' && <OverlayHole />}
          </DesktopOverlayHoldWrapper>
          {state.showAiPlayers ? (
            <>
              <StyledAI id="1" state={state} showOverlay={state.tutorialStep === 'AI1'} />
              <Desktop />
              <StyledAI id="2" state={state} showOverlay={state.tutorialStep === 'AI2'} />
            </>           
          ) : (
            <>
              <div />
              <div />
              <div />
            </>
          )}

          {/* Row 3 */}
          <StyledOverlayHoleWrapper grid="bets">
            <Centered>
              <h2>Bets</h2>
            </Centered>
            {BET_TYPES.map((bet) => (
              <Bet bet={bet} state={state} dispatch={dispatch} showOverlay={bet === state.tutorialStep} />
            ))}
            <Centered>Chips: {state.player.chips}</Centered>
            {state.tutorialStep === 'BETS' && <OverlayHole />}
          </StyledOverlayHoleWrapper>
          <StyledOverlayHoleWrapper grid="player">
            <Mobile>Your Cards</Mobile>
            <DesktopPlayerPile
              width="130px"
              id="player"
              fanned="hand"
            />
            <MobilePlayerPile
              width="80px"
              id="player"
              fanned="hand"
            />
            {state.tutorialStep === 'PLAYER' && <OverlayHole />}
          </StyledOverlayHoleWrapper>
          <TrueCentered grid="buttons">
            <div>
              <button onClick={() => dispatch(next())} className="btn btn-primary">
                {NEXT_STEP[state.step]}
              </button>
            </div>
            {state.step === DEAL && (
              <div className="mt-5">
                <button onClick={() => dispatch(fold())} className="btn btn-danger">
                  Fold
                </button>
              </div>
            )}
            {state.error && (
              <h3>{state.error}</h3>
            )}
            {state.player.winner && (
              <Winner />
            )}
            {state.player.loser && (
              <Loser />
            )}
            {state.tutorialStep === 'BUTTONS' && <OverlayHole />}
          </TrueCentered>
        </StyledWrapper>
      )}
    </PlayingCards>
  )
};

export default ThreeCardPoker;

import React from 'react';
import styled from 'styled-components';

const OverlayHole = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export default OverlayHole;
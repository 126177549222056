import { START } from './constants';

const initialState = {
  piles: {
    player: {
      cards: [],
    },
    dealer: {
      cards: [],
    },
    ai1: {
      cards: [],
    },
    ai2: {
      cards: [],
    },
  },
  ai: {
    1: {
      bets: {},
      chips: 500,
    },
    2: {
      bets: {},
      chips: 500,
    },
  },
  player: {
    chips: 500,
    bets: {},
  },
  step: START,
  showAiPlayers: true,
  tutorialStep: 'STOP',
};

export default initialState;
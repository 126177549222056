import React from 'react';
import { Prism } from 'react-syntax-highlighter';

const getSections = (copy) => {
  let idx = copy.indexOf('[code');
  const sections = [];
  if (idx === -1) {
    return [{
      copy,
      type: 'html',
    }];
  } else {
    sections.push({
      copy: copy.substr(0, idx),
      type: 'html',
    });
    while(idx !== -1) {
      const idxB = copy.indexOf(']', idx);
      const idxEnd = copy.indexOf('[/code]', idxB) + 7;
      if (idxEnd === -1 + 7) {
        idx = -1;
        sections.push({
          copy: copy.substr(idx),
          type: 'html',
        });
      } else {
        const match = copy.substr(idx, idxB).match(/type="(.+)"/);
        const type = match ? match[1] : 'javascript';
        const nextOpen = copy.indexOf('[code', idxEnd);
        sections.push({
          copy: copy.substr(idxB + 1, idxEnd - idxB - 8).trim(),
          type,
        });
        if (nextOpen === -1) {
          sections.push({
            copy: copy.substr(idxEnd),
            type: 'html',
          });
        } else {
          sections.push({
            copy: copy.substr(idxEnd, nextOpen - idxEnd),
            type: 'html',
          });
        }
        idx = nextOpen;
      }
    }
  }
  return sections;
}

const Body = ({ copy }) => {
  return (
    <>
      {getSections(copy).map(({ copy, type }) =>
        type === 'html'
          ?
            <div dangerouslySetInnerHTML={{ __html: copy }} key={copy} />
          :
            <Prism language={type} key={copy}>{copy}</Prism>
        )}
    </>
  );
};

export default Body;

import React from 'react';
import ContentQuery from './ContentQuery';

const ContentQueryWrapper = (props) => {
  // TODO: Get the URL
  const url = props.location.pathname || '/';
  if (url === '/') {
    return <ContentQuery />;
  }
  if (url.startsWith('/category/')) {
    const match = url.match(/^\/category\/([^/]*)/);
    const tag = match[1];
    return <ContentQuery tag={tag} />;
  }
  return <ContentQuery url={url} />;
};

export default ContentQueryWrapper;

import React from 'react';
import styled from 'styled-components';
import { Pile } from 'cards';
import { rows } from './reducer/initial-state';
import { deal, shuffle, click } from './reducer/action-creators';
import { GameWrapper } from '../../components/Styled';

const StyledGameWrapper = styled(GameWrapper)`
  justify-content: flex-start;
  @media (min-width: 768px) and (max-width: 850px) {
    min-width: calc(99vw - 30px);
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  margin-bottom: 10px !important;
`;

const RowsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  @media (max-width: 768px) {
    flex-flow: row;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  & > div {
    padding-right: 5px;
    @media (max-width: 1024px) and (min-width: 768px) {
      padding-right: 0;
      &:not(:first-child) {
        margin-left: -15px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-flow: column;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    margin-top: 30px !important;
    display: block;
  }
`;

const Desktop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobilePile = styled(Pile)`
  &:not(:first-child) {
    margin-top: -50%;
  }
`;

const StyledState = styled.div`
  font-size: 30px;
  line-height: 31px;
  font-weight: bold;
`;

const Addiction = ({ state, dispatch }) => (
  <StyledGameWrapper>
    <ButtonWrapper>
      <button className="btn btn-danger mr-2" onClick={() => dispatch(deal())}>Deal</button>
      <StyledState>
        {state.isWinner && <>You Win!</>}
        {!state.isWinner && state.isEndGame && <>Game Over</>}
      </StyledState>
      <div>
        {!state.newGame && <button className="btn btn-info ml-2" onClick={() => dispatch(shuffle())}>Shuffle {state.shuffleCount} / 3</button>}
      </div>
    </ButtonWrapper>
    <Desktop>
      <RowsWrapper>
        {rows.map(piles => (
          <RowWrapper>
            {piles.map(pileId => (
              <Pile id={pileId} fanned="right" width="65px" onClick={() => dispatch(click(pileId))} />
            ))}
          </RowWrapper>
        ))}
      </RowsWrapper>
    </Desktop>
    <Mobile>
      <RowsWrapper>
        {rows.map(piles => (
          <RowWrapper>
            {piles.map(pileId => (
              <MobilePile id={pileId} width="45px" onClick={() => dispatch(click(pileId))} />
            ))}
          </RowWrapper>
        ))}
      </RowsWrapper>
    </Mobile>
  </StyledGameWrapper>
);

export default Addiction;
import gql from 'graphql-tag';

export const TAG_QUERY = gql`
  query {
    getTags {
      name
      postCount
    }
  }
`;

export const POST_QUERY = gql`
  query getContent($tag: String, $url: String, $limit: Int, $offset: Int) {
    getContent(tag: $tag, url: $url, limit: $limit, offset: $offset) {
      title
      body
      type
      publishedOn
      tags
      url
    }
  }
`;

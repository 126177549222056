import { DEAL, SHUFFLE, CLICK } from './constants';
import { deal, shuffle, click, isWinner, isEndGame } from './actions';

const actionLookup = {
  [DEAL]: deal,
  [SHUFFLE]: shuffle,
  [CLICK]: click,
};

const reducer = (state, action) => {
  const { error, ...cleanState } = state;
  const actionFunc = actionLookup[action.type];
  if (typeof actionFunc === 'function') {
    const newState = actionFunc(cleanState, action);
    return {
      ...newState,
      isWinner: isWinner(newState),
      isEndGame: isEndGame(newState),
    };
  }
  return state;
};

export default reducer;
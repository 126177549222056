import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { EASY, MEDIUM, HARD } from './players';
import { deal, resetGame } from './reducer/action-creators';

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '25%',
  }
};

const StyledH2 = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const StyledDiv = styled.div`
  margin: 10px 0 20px;
  text-align: center;
`;

const StyledCloseButton = styled.button.attrs({
  className: 'btn btn-sm',
})`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const NewGameModal = ({ dispatch, state }) => {
  const isOpen = state.newGameOpen;

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
    >
      <StyledCloseButton onClick={() => dispatch(resetGame())}>X</StyledCloseButton>
      <StyledH2>New Game</StyledH2>
      <StyledDiv>Pick a difficulty</StyledDiv>
      <ButtonDiv>
        <button onClick={() => dispatch(deal(EASY))} className="btn btn-primary mr-3">Easy</button>
        <button onClick={() => dispatch(deal(MEDIUM))} className="btn btn-primary mr-3">Medium</button>
        <button onClick={() => dispatch(deal(HARD))} className="btn btn-primary">Hard</button>
      </ButtonDiv>
    </Modal>
  )
};

export default NewGameModal;
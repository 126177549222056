import React from 'react';
import { Button } from 'react-bootstrap';
import OgTitle from './OgTitle';
import OgDesc from './OgDesc';
import Content from './Content';

const MultiContent = ({ content, hasMore, loadMore }) => (
  <>
    {content.map(item => (
      <div className="mb-4" key={item.url}>
        <Content {...item} isInList />
      </div>
    ))}
    {hasMore && (
      <Button onClick={loadMore}>Load More</Button>
    )}
    <OgTitle />
    <OgDesc content="Adventures of a Code Ninja" />
  </>
);

export default MultiContent;

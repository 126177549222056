import React from 'react';
import Ad from './Ad';
import TagCloud from './TagCloud';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledTagCloud = styled(TagCloud)`
  @media (max-width: 992px) {
    display: none;
  }
`;

const Sidebar = () => (
  <Wrapper>
    <div className="mb-4">
      <Ad client="pub-1532657361966464" slot="8248124328" />
    </div>
    <StyledTagCloud />
  </Wrapper>
);

export default Sidebar;

import { cards } from 'cards';

const shuffleArray = (arr) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const cascades = [1,2,3,4,5,6,7].map((n) => `cascade_${n}`);

export const deal = (state) => {
  const shuffled = shuffleArray(cards);

  const piles = {
    deck: {
      cards: [],
      facedown: true,
    },
    discard: {
      cards: [],
    },
    foundation_h: {
      cards: [],
    },
    foundation_s: {
      cards: [],
    },
    foundation_c: {
      cards: [],
    },
    foundation_d: {
      cards: [],
    },
  };
  cascades.forEach(c => piles[c] = { cards: [] });

  // TODO: Deal
  for(let i=0;i<7;i++) {
    for(let j=i;j<7;j++) {
      const card = shuffled.shift();
      piles[cascades[j]].cards.push({
        ...card,
        facedown: i !== j,
      });
    }
  }

  piles.deck.cards = shuffled;

  // Return state
  return {
    ...state,
    piles,
  }
};

export const flip = (state) => {
  const { piles } = state;

  if (piles.deck.cards.length > 0) {
    const flipped = [];
    while (piles.deck.cards.length > 0 && flipped.length < 3) {
      flipped.push(piles.deck.cards.pop());
    }
    piles.discard.cards = piles.discard.cards.concat(flipped);
  } else {
    piles.deck.cards = piles.discard.cards.reverse();
    piles.discard.cards = [];
  }

  return {
    ...state,
    piles,
  };
};

export const ranks = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
const colors = {
  'd': 'RED',
  'D': 'RED',
  'h': 'RED',
  'H': 'RED',
  'c': 'BLACK',
  'C': 'BLACK',
  's': 'BLACK',
  'S': 'BLACK',
};

export const click = (state, { pile }) => {
  if (pile === 'deck') {
    return flip(state);
  }
  
  const { cards } = state.piles[pile];
  if (cards.length === 0) {
    return state;
  }

  const topCard = cards[cards.length-1];

  if (topCard.facedown) {
    cards[cards.length-1].facedown = false;
    return {
      ...state,
      piles: {
        ...state.piles,
        [pile]: {
          cards,
        },
      },
    };
  }

  let moved = false;

  // Can it go to a foundation?
  const foundation_pile = `foundation_${topCard.suit.toLowerCase()}`;
  const p = state.piles[foundation_pile].cards;
  if (p.length === 0) {
    if (topCard.rank === 'A') {
      state.piles[foundation_pile].cards = [topCard];
      cards.splice(cards.length-1, 1);
      state.piles[pile].cards = cards;
      moved = true;
    }
  } else {
    if (ranks.indexOf(topCard.rank) - 1 === ranks.indexOf(p[p.length-1].rank)) {
      state.piles[foundation_pile].cards = [topCard];
      cards.splice(cards.length-1, 1);
      state.piles[pile].cards = cards;
      moved = true;
    }
  }

  if (!moved) {
    if (cascades.indexOf(pile) > -1) {
      const faceupCards = cards.filter(card => !card.facedown);
      for(let i=0;i<faceupCards.length;i++) {
        if (moved) {
          break;
        }
        const thisTopCard = faceupCards[i];
        // Check to see if it can go to a cascade
        for (const cascade of cascades) {
          const c = state.piles[cascade].cards;
          if (c.length !== 0) {
            const bottomCard = c[c.length-1];
            if (colors[bottomCard.suit] !== colors[thisTopCard.suit] && ranks.indexOf(thisTopCard.rank) + 1 === ranks.indexOf(bottomCard.rank)) {
              state.piles[cascade].cards = state.piles[cascade].cards.concat(faceupCards.slice(i));
              cards.splice(cards.length - faceupCards.length + i, faceupCards.length);
              state.piles[pile].cards = cards;
              moved = true;
              break;
            }
          }
        }
      }
    } else {
      // Check to see if it can go to a cascade
      for (const cascade of cascades) {
        const c = state.piles[cascade].cards;
        if (c.length !== 0) {
          const bottomCard = c[c.length-1];
          if (colors[bottomCard.suit] !== colors[topCard.suit] && ranks.indexOf(topCard.rank) + 1 === ranks.indexOf(bottomCard.rank)) {
            state.piles[cascade].cards.push(topCard);
            cards.splice(cards.length-1, 1);
            state.piles[pile].cards = cards;
            moved = true;
            break;
          }
        }
      }
    }
  }

  if (!moved) {
    // Check to see if it can go to a cascade
    for (const cascade of cascades) {
      const c = state.piles[cascade].cards;
      if (c.length === 0) {
        if (cascades.indexOf(pile) > -1) {
          const faceupCards = cards.filter(card => !card.facedown);
          state.piles[cascade].cards = state.piles[cascade].cards.concat(faceupCards);
          cards.splice(cards.length - faceupCards.length, faceupCards.length);
          state.piles[pile].cards = cards;
          moved = true;
          break;
        } else {
          state.piles[cascade].cards = [topCard];
          cards.splice(cards.length-1, 1);
          state.piles[pile].cards = cards;
          moved = true;
          break;
        }
      }
    }
  }

  if (pile === 'discard' && !moved) {
    return flip(state);
  }

  return {
    ...state,
    piles: {
      ...state.piles,
    },
  };
};

export const checkForWin = (state) => {
  const { piles } = state;
  if ( piles.foundation_c.cards.length === 1 && piles.foundation_c.cards[0].rank === 'K' &&
       piles.foundation_d.cards.length === 1 && piles.foundation_d.cards[0].rank === 'K' &&
       piles.foundation_h.cards.length === 1 && piles.foundation_h.cards[0].rank === 'K' &&
       piles.foundation_s.cards.length === 1 && piles.foundation_s.cards[0].rank === 'K' ) {
    return {
      ...state,
      winner: true,
      endGame: true,
    };
  }
  return state;
};

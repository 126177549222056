import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import Home from '../Home';
import { Freecell, SliderPuzzle } from 'games';
import Rummy from '../../cardGames/Rummy';
import ThreeCardPoker from '../../cardGames/ThreeCardPoker';
import BlackJack from '../../cardGames/BlackJack';
import Addiction from '../../cardGames/Addiction';
import Klondike from '../../cardGames/Klondike';
import 'bootstrap/dist/css/bootstrap.css';

const App = () => (
  <div className="container-lg mt-4 mb-4 h-100" id="main-container">
    <Switch>
      <Route exact path="/games" component={Home} />
      <Route exact path="/games/freecell" component={Freecell} />
      <Route exact path="/games/slider" component={SliderPuzzle} />
      <Route exact path="/games/rummy" component={Rummy} />
      <Route exact path="/games/poker" component={ThreeCardPoker} />
      <Route exact path="/games/blackjack" component={BlackJack} />
      <Route exact path="/games/addiction" component={Addiction} />
      <Route exact path="/games/klondike" component={Klondike} />
    </Switch>
  </div>
);

export default App;

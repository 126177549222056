import { draw, discard, selectCard, drawFromDiscard, meld } from '../reducer/action-creators';
import { ranksA, ranksB, getRuns, sortHand } from '../reducer/actions';

const findMelds = (cards, min = 3) => {
  let melds = [];

  // Grab all runs
  const { runs, remaining } = getRuns(cards, ranksA, min);
  melds = melds.concat(runs);
  const { runs: runsB, remaining: remainingB } = getRuns(remaining, ranksB, min);
  melds = melds.concat(runsB);

  // Grab all sets
  const sorted = sortHand(remainingB);
  while(sorted.length > min) {
    let i = 1;
    for(;i<sorted.length;i++) {
      if (sorted[0].rank !== sorted[i].rank) {
        break;
      }
    }
    if (i > min) {
      melds.push(sorted.splice(0, i-1));
    } else {
      break;
    }
  }

  return melds;
};

const scoresByRankA = {
  A: 5,
  2: 5,
  3: 5,
  4: 5,
  5: 5,
  6: 5,
  7: 5,
  8: 5,
  9: 5,
  10: 10,
  T: 10,
  J: 10,
  K: 10,
};
const scoresByRankB = {
  2: 5,
  3: 5,
  4: 5,
  5: 5,
  6: 5,
  7: 5,
  8: 5,
  9: 5,
  10: 10,
  T: 10,
  J: 10,
  K: 10,
  A: 15,
};
const score = (hand, melds) => {
  const handScore = hand.reduce(
    (acc, { rank }) => acc + scoresByRankA[rank],
    0,
  );
  const meldScore = melds.reduce(
    (acc, cards) => {
      if (cards[0].rank === cards[1].rank) {
        return acc + cards.length * scoresByRankB[cards[0].rank];
      }
      if (cards[0].rank === 'A') {
        return acc + cards.reduce(
          (accB, { rank }) => accB + scoresByRankA[rank],
          0,
        );
      }
      return acc + cards.reduce(
        (accB, { rank }) => accB + scoresByRankB[rank],
        0,
      );
    },
    0,
  );

  return meldScore - handScore;
};

export const drawTime = (
  dispatch,
  {
    piles: {
      player2,
      discard,
    },
    melds: {
      players: myMelds,
    }
  }
) => {
  const myCards = player2.cards;
  const discardCards = discard.cards;
  const currentScore = score(myCards, myMelds);
 
  // Should I pull mulitple cards from discard?
  for(let i=0;i<discardCards.length;i++) {
    const [bottomCard] = discardCards.slice(i);
    const newHand = [...discardCards.slice(i), ...myCards];
    const melds = findMelds(newHand);
    const filteredHand = newHand.filter((card) => {
      for(let j=0;j<melds.length;j++) {
        for(let k=0;k<melds[j].length;k++) {
          if (card.rank === melds[j][k].rank && card.suit === melds[j][k].suit) {
            return false;
          }
        }
      }
      return true;
    });
    // Calculate new hand's score
    const newScore = score(filteredHand, [...melds, myMelds]);
    // If score after is lower than before: continue;
    if (newScore < currentScore) {
      continue;
    }
    for(let j=0;j<melds;j++) {
      for(let k=0;k<melds[j].length;k++) {
        const card = melds[j][k];
        if (card.suit === bottomCard.suit && card.rank === bottomCard.rank) {
          return dispatch(drawFromDiscard(bottomCard));
        }
      }
    }
  }

  // Should I pull the top card from discard?
  const topDiscardCard = discardCards[discardCards.length-1];
  const newHand = [...myCards, topDiscardCard];
  const melds = findMelds(newHand, 2);
  for(let j=0;j<melds;j++) {
    for(let k=0;k<melds[j].length;k++) {
      const card = melds[j][k];
      if (card.suit === topDiscardCard.suit && card.rank === topDiscardCard.rank) {
        return dispatch(drawFromDiscard(topDiscardCard));
      }
    }
  }
  const num = myCards.reduce(
    (acc, val) => acc + (val.rank === topDiscardCard.rank ? 1 : 0),
    0,
  );
  if (num >= 2) {
    return dispatch(drawFromDiscard(topDiscardCard));
  }
  const withDiscard = sortHand([...myCards, topDiscardCard]);
  const runsA = getRuns(withDiscard, ranksA, 2);
  if (runsA.remaining.filter(c => c.suit === topDiscardCard.suit && c.rank === topDiscardCard.rank).length === 0) {
    return dispatch(drawFromDiscard(topDiscardCard));
  }
  const runsB = getRuns(withDiscard, ranksB, 2);
  if (runsB.remaining.filter(c => c.suit === topDiscardCard.suit && c.rank === topDiscardCard.rank).length === 0) {
    return dispatch(drawFromDiscard(topDiscardCard));
  }

  // Pull from stock!
  return dispatch(draw());
};

const addToMelds = (cards, melds) => {
  const l = cards.length;
  for(let i=0;i<melds;i++) {
    const meld = melds[i];
    if (meld[0].rank === meld[1].rank) {
      if (meld.length === 4) {
        // Full set
        continue;
      }
      for(let j=0;j<l;j++) {
        if (cards[j].rank === meld[0].rank) {
          if (!cards[j].selected) {
            return selectCard(cards[j]);
          }
          return meld();
        }
      }
    }
    for(let j=0;j<l;j++) {
      const { remaining } = getRuns([...meld, cards[j]], ranksA);
      if (remaining.length === 0) {
        if (!cards[j].selected) {
          return selectCard(cards[j]);
        }
        return meld();
      }
      const { remaining: rem } = getRuns([...meld, cards[j]], ranksB);
      if (rem.length === 0) {
        if (!cards[j].selected) {
          return selectCard(cards[j]);
        }
        return meld();
      }
    }
  }
  return false;
};

export const playTime = (
  dispatch,
  {
    piles: {
      player2: { cards }
    },
    melds: {
      player,
      player2,
    }
  }
) => {
  if (cards.length > 2) {
    // Lay down a meld from my hand?
    if (cards[0].rank === cards[1].rank && cards[0].rank === cards[2].rank) {
      let l = 3;
      if (cards.length > 3 && cards[0].rank === cards[3].rank) {
        l = 4;
      }
      for(let i=0;i<l;i++) {
        if (!cards[i].selected) {
          return dispatch(selectCard(cards[i]));
        }
      }
      return dispatch(meld());
    }
    const { runs } = getRuns(cards, ranksA);
    if (runs.length > 0) {
      let l = runs.length;
      for(let i=1;i<runs.length;i++) {
        if (runs[i].suit === runs[0].suit && runs[i].rankIndex !== (runs[0].rankIndex+i)) {
          l = i;
          break;
        }
      }
      for(let i=0;i<l;i++) {
        if (!runs[i].selected) {
          return dispatch(selectCard(runs[i]));
        }
      }
      return dispatch(meld());
    }

    const { runs: runsB } = getRuns(cards, ranksB);
    if (runsB.length > 0) {
      let l = runsB.length;
      for(let i=1;i<runsB.length;i++) {
        if (runsB[i].suit === runsB[0].suit && runsB[i].rankIndex !== (runsB[0].rankIndex+i)) {
          l = i;
          break;
        }
      }
      for(let i=0;i<l;i++) {
        if (!runsB[i].selected) {
          return dispatch(selectCard(runsB[i]));
        }
      }
      return dispatch(meld());
    }
  }

  // Add to a meld I have?
  const addToMyMelds = addToMelds(cards, player2);
  if (!!addToMyMelds) {
    return dispatch(addToMyMelds);
  }

  // Add to a meld the opponent has?
  const addToMyMeldsB = addToMelds(cards, player);
  if (!!addToMyMeldsB) {
    return dispatch(addToMyMeldsB);
  }

  // Discard a card the opponent likely cannot play with!
  const selectedCards = cards.filter(c => !!c.selected);
  if (selectedCards.length === 0) {
    return dispatch(selectCard(cards[cards.length-1]));
  }
  if (selectedCards.length === 1) {
    return dispatch(discard());
  }
  return dispatch(selectCard(selectedCards[0]));
};
import React from 'react';
import { withTagsQuery } from '../apollo/query';
import { TagCloud as RTagCloud } from 'react-tagcloud';
import { Link } from 'react-router-dom';

const styles = {
  margin: '0 3px',
  display: 'inline-block',
  color: '#000',
};

const cloudLink = (tag, size) => {
  const fontSize = size + 'px'
  const key = tag.key || tag.value
  const style = { ...styles, fontSize }
  return (
    <Link
      to={`/category/${tag.value}/`}
      className="tag-cloud-tag"
      style={style}
      key={key}
    >
      {tag.value}
    </Link>
  );
}

const TagCloud = ({ className, tags: { data }, history }) => {
  if (!data) {
    return null;
  }
  const tags = [];
  data.getTags.forEach(({ name, postCount }) => {
    tags.push({ value: name, count: postCount });
  });
  return (
    <div className={className}>
      <h4>Tag Cloud</h4>
      <RTagCloud
        minSize={12}
        maxSize={35}
        tags={tags}
        disableRandomColor
        renderer={cloudLink}
      />
    </div>
  );
};

export default withTagsQuery(TagCloud);

import { DEAL, FLIP, CLICK } from './constants';
import { deal, flip, click, checkForWin } from './actions';

const reducerMap = {
  [DEAL]: deal,
  [FLIP]: flip,
  [CLICK]: click,
}

const reducer = (state, action) => {
  if (reducerMap[action.type]) {
    return checkForWin(reducerMap[action.type](state, action));
  }
  return state;
};

export default reducer;
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  min-width: 768px;
  min-height: 640px;
  max-width: 1024px;
  max-height: 90vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: #477148;
  padding: 15px;
  & > div {
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
`;

export default StyledWrapper;

import { EASY } from '../players';

const initialState = {
  turn: false,
  piles: {},
  melds: {},
  opponent: EASY,
  newGameOpen: false,
};

export default initialState;
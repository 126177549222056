import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const getOgInfo = (children) => {
  const res = {};
  if (!children) {
    return res;
  }
  if (children.length) {
    return children.reduce(
      (acc, rec) => Object.assign(acc, getOgInfo(rec)),
      res,
    );
  }
  const { type, props: { name, children: kids, content } } = children;
  if (type === 'title') {
    res.ogTitle = kids;
  } else if (type === 'meta' && name === 'description') {
    res.ogDesc = content;
  }
  return res;
};

const OgHelmet = ({ children }) => {
  const { ogTitle, ogDesc } = getOgInfo(children);
  return (
    <Helmet>
      {children}
      {ogTitle &&
        <meta
          property="og:title"
          content={ogTitle}
        />
      }
      {ogDesc &&
        <meta
          property="og:description"
          content={ogDesc}
        />
      }
    </Helmet>
  );
};

OgHelmet.propTypes = {
  children: PropTypes.node,
};

export default OgHelmet;

const lookupRank = {
  A: 0,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  J: 10,
  Q: 10,
  K: 10,
};

const scoreHand = (cards) => {
  const score = cards.reduce(
    (acc, val) => (acc + lookupRank[val.rank]),
    0,
  );
  const aceCount = cards.filter(({ rank }) => rank === 'A').length;
  if ((score + aceCount) >= 21 || aceCount === 0) {
    return score + aceCount;
  }
  if ((score + 11 + aceCount - 1) <= 21) {
    return score + 11 + aceCount - 1;
  }
  return score + aceCount;
};

export default scoreHand;
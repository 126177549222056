import {
  DEAL,
  REVEAL,
  SCORE,
  NEXT,
  CHANGE_BET,
  START,
  FOLD,
  TOGGLE_AI,
  START_TUTORIAL,
  NEXT_TUTORIAL_STEP,
} from './constants';
import {
  BET_ANTE,
  BET_PLAY,
} from '../constants';
import initialState from './initial-state';
import {
  deal,
  reveal,
  score,
  changeBet,
  fold,
  nextGame,
  toggleAi,
  startTutorial,
  nextTutorialStep,
} from './actions';

export { initialState };

const scrubError = (state) => {
  const {
    error,
    ...scrubbedState
  } = state;
  return scrubbedState;
};

const next = (state) => {
  switch (state.step) {
    case DEAL:
      if (!state.player.bets[BET_PLAY] || state.player.bets[BET_ANTE] > state.player.bets[BET_PLAY]) {
        return {
          ...state,
          error: 'You must bet the ante amount on play',
        };
      }
      return {
        ...(scrubError(reveal(state))),
        step: REVEAL,
      };
    case REVEAL:
      return {
        ...(score(state)),
        step: SCORE,
      };
    case SCORE:
      return {
        ...(nextGame(state)),
        step: START,
      };
    case START:
      if (!state.player.bets[BET_ANTE] || state.player.bets[BET_ANTE] < 5) {
        return {
          ...state,
          error: 'You must place an ante bet of at least 5',
        };
      }
      return {
        ...(scrubError(deal(state))),
        step: DEAL,
      };
    default:
      return initialState;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case NEXT:
      return next(state);
    case CHANGE_BET:
      return changeBet(state, action);
    case FOLD:
      return fold(state);
    case TOGGLE_AI:
      return toggleAi(state);
    case START_TUTORIAL:
      return startTutorial(state);
    case NEXT_TUTORIAL_STEP:
      return nextTutorialStep(state);
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Container, Row, Col } from 'react-bootstrap';

const MainWrapper = ({ children, noSideBar }) => (
  <>
    <Navbar />
    {noSideBar ? children : (
      <Container className="mb-4 mt-4">
        <Row>
          <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 9 }}>
            {children}
          </Col>
          <Col xs={{ order: 1, span: 12 }} md>
            <Sidebar />
          </Col>
        </Row>
      </Container>
    )}

    <Footer />
  </>
);

export default MainWrapper;

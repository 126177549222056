import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import fetch from 'node-fetch';
import { GRAPHQL_URL } from '../config';

export const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  fetch,
});
export const context = (_, { headers }) => {
  const info = /* getUserInfo() || */ {};
  const { token } = info;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};
const authLink = setContext(context);
const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default client;

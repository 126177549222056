import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import BlogApp from './blog/App';
import Home from './components/Home';
import Nav from './components/Nav';
import Ad from './components/Ad';
import { Freecell, SliderPuzzle } from 'games';
import Rummy from './cardGames/Rummy';
import ThreeCardPoker from './cardGames/ThreeCardPoker';
import BlackJack from './cardGames/BlackJack';
import Addiction from './cardGames/Addiction';
import Klondike from './cardGames/Klondike';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const current_year = () => new Date().getFullYear();

const App = () => {
  return (
    <>
      <Nav className="mb-3" />
      <Ad />
      <div className="container-lg mt-4 mb-4 h-100" id="main-container">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/freecell" component={Freecell} />
          <Route exact path="/slider" component={SliderPuzzle} />
          <Route exact path="/rummy" component={Rummy} />
          <Route exact path="/poker" component={ThreeCardPoker} />
          <Route exact path="/blackjack" component={BlackJack} />
          <Route exact path="/addiction" component={Addiction} />
          <Route exact path="/klondike" component={Klondike} />
        </Switch>
      </div>
      <div className="container text-center mt-5 mb-2">
        Copyright &copy; {/*2019 - */}{current_year()} Barry Gilbert
      </div>
    </>
  );
};

export default BlogApp;

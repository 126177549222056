import React from 'react';
import { PlayingCards } from 'cards';
import reducer, { initialState } from './reducer';
import Game from './Game';

const BlackJack = () => (
  <PlayingCards
    initialState={initialState}
    reducer={reducer}
  >
    {(state, dispatch) => (
      <Game state={state} dispatch={dispatch} />
    )}
  </PlayingCards>
);

export default BlackJack;
import { cards } from 'cards';
import { DEAL, HIT, START, STAY } from './constants';
import scoreHand from './scoreHand';

export const initialState = {
  piles: {
    dealer: {
      cards: [],
    },
    player: {
      cards: [],
    },
    deck: {
      cards: [],
    }
  },
  step: START,
};

const shuffleArray = (arr) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const deal = (state) => {
  const shuffled = shuffleArray(cards);
  const {
    winner,
    loser,
    winnings,
    ...cleanState
  } = state;
  const piles = {};
  const dealOrder = ['dealer', 'player'];
  dealOrder.forEach((pile) => {
    piles[pile] = {
      cards: [],
      facedown: true,
    };
  });
  delete piles.player.facedown;
  [0,1].forEach(() => dealOrder.forEach((pile) => {
    piles[pile].cards.push(shuffled.shift());
  }));
  piles.deck = {
    cards: shuffled
  };
  return {
    ...cleanState,
    piles,
    step: DEAL,
  };
};

const dealerHit = (state) => {
  const { cards } = state.piles.dealer;
  const score = scoreHand(cards);
  if (score > 16) {
    return state;
  }
  cards.push(state.piles.deck.cards[0]);
  return {
    ...state,
    piles: {
      ...state.piles,
      dealer: {
        ...state.piles.dealer,
        cards,
      },
      deck: {
        cards: state.piles.deck.cards.slice(1),
      },
    },
  };
};

const hit = (state) => {
  const { cards } = state.piles.player;
  cards.push(state.piles.deck.cards[0]);
  const score = scoreHand(cards);
  const newState = dealerHit({
    ...state,
    piles: {
      ...state.piles,
      player: {
        ...state.piles.player,
        cards,
      },
      deck: {
        cards: state.piles.deck.cards.slice(1),
      },
    },
    step: HIT,
  });
  if (score == 21) {
    return dealerHits({
      ...newState,
      piles: {
        ...newState.piles,
        dealer: {
          ...newState.piles.dealer,
          facedown: false,
        }
      },
      winner: true,
      step: STAY,
      score,
    });
  }
  if (score > 21) {
    return dealerHits({
      ...newState,
      piles: {
        ...newState.piles,
        dealer: {
          ...newState.piles.dealer,
          facedown: false,
        }
      },
      loser: true,
      step: STAY,
      score,
    });
  }
  return newState;
};

const dealerHits = (state) => {
  const newState = dealerHit(state);
  if (state.piles.dealer.cards.length === newState.piles.dealer.cards.length) {
    return state;
  }
  return dealerHits(newState);
}

const stay = (state) => {
  const finalState = dealerHits(state);

  const playerCards = finalState.piles.player.cards;
  const dealerCards = finalState.piles.dealer.cards;
  const playerScore = scoreHand(playerCards);
  const dealerScore = scoreHand(dealerCards);

  const loser = dealerScore <= 21 && playerScore < dealerScore;
  const winner = dealerScore > 21 || playerScore >= dealerScore;

  return {
    ...finalState,
    piles: {
      ...finalState.piles,
      dealer: {
        ...finalState.piles.dealer,
        facedown: false,
      }
    },
    score: playerScore,
    winner,
    loser,
    step: STAY,
  };
};

const newGame = (state) => ({
  ...state,
  ...initialState
});

const reducer = (state, action) => {
  switch(action.type) {
    case DEAL:
      return deal(state);
    case HIT:
      return hit(state);
    case STAY:
      return stay(state);
    case START:
      return newGame(state);
    default:
      return state;
  }
};

export default reducer;
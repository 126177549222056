import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Pile } from 'cards';
import Melds from './Melds';
import NewGameModal from './NewGameModal';
import EndGameModal from './EndGameModal';
import { DRAW } from './reducer/constants';
import { deal, draw, discard, selectCard, meld, drawFromDiscard, newGame } from './reducer/action-creators';
import { MEDIUM, HARD, easy, medium, hard } from './players';
import GameWrapper from '../../components/Styled/GameWrapper';

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const StyledPile = (props) => <Pile width="75px" {...props} />;
const StyledPile2 = (props) => <Pile width="50px" {...props} />;
const StyledMelds = (props) => <Melds width="50px" {...props} />;
const StyledMeldsMobile = (props) => <Melds width="30px" {...props} />;
const StyledPile2Mobile = (props) => <Pile width="30px" {...props} />;

const MyStyledPile = styled(StyledPile)`
  height: 105px;
`;

const MyStyledPileMobile = styled(StyledPile)`
  height: 105px;
  & > div:not(:last-child) {
    width: 15px;
  }
`;

const MyStyledPile2 = styled(StyledPile2)`
  margin-top: 10px !important;
  height: 70px;
`;

const MyStyledPile2Mobile = styled(StyledPile2Mobile)`
  margin-top: 10px !important;
  & > div:not(:last-child) {
    width: 15px;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const DM = ({desktop, mobile}) => (
  <div>
    <Mobile>{mobile}</Mobile>
    <Desktop>{desktop}</Desktop>
  </div>
);

const playTime = (dispatch, state) => {
  switch(state.opponent) {
    case HARD:
      return hard.playTime(dispatch, state);
    case MEDIUM:
      return medium.playTime(dispatch, state);
    default:
      return easy.playTime(dispatch, state);
  }
};

const drawTime = (dispatch, state) => {
  switch(state.opponent) {
    case HARD:
      return hard.drawTime(dispatch, state);
    case MEDIUM:
      return medium.drawTime(dispatch, state);
    default:
      return easy.drawTime(dispatch, state);
  }
};

const Game = ({ state, dispatch }) => {
  const isTurn = state.turn === 'player';
  const canDraw = isTurn && state.lastStep !== DRAW;
  const canDiscard = isTurn && state.lastStep === DRAW;

  useEffect(() => {
    if (state.turn === 'player2') {
      let timeout = setTimeout(() => {
        timeout = false;
        if (state.lastStep !== DRAW) {
          return drawTime(dispatch, state);
        }
        return playTime(dispatch, state);
      }, 1000);
      return () => {
        if (!!timeout) {
          clearTimeout(timeout);
        }
      };
    }
  });

  return (
    <React.Fragment>
      <GameWrapper>
        <FlexWrapper>
          <button className="btn btn-danger" onClick={() => dispatch(newGame())}>New Game</button>
          {canDiscard &&
            <React.Fragment>
              <button className="btn btn-primary" onClick={() => dispatch(meld())}>Meld</button>
              <button className="btn btn-primary" onClick={() => dispatch(discard())}>Discard</button>
            </React.Fragment>
          }
          {state.error &&
            <div>{state.error}</div>
          }
        </FlexWrapper>
        <DM
          desktop={<MyStyledPile2 id="player2" fanned="right" />}
          mobile={<MyStyledPile2Mobile id="player2" fanned="right" />}
        />
        <DM
          desktop={<StyledMelds melds={state.melds.player2} />}
          mobile={<StyledMeldsMobile melds={state.melds.player2} />}
          />
        <FlexWrapper>
          <div onClick={() => canDraw && dispatch(draw())}>
            <StyledPile id="stock" />
          </div>
          <DM
            desktop={<StyledPile
              id="discard"
              fanned="right"
              onClick={({ card }) => canDraw && dispatch(drawFromDiscard(card))}
            />}
            mobile={<MyStyledPileMobile
              id="discard"
              fanned="right"
              onClick={({ card }) => canDraw && dispatch(drawFromDiscard(card))}
            />}
          />
        </FlexWrapper>
        <DM
          desktop={<StyledMelds melds={state.melds.player} />}
          mobile={<StyledMeldsMobile melds={state.melds.player} />}
        />
        <DM
          desktop={<MyStyledPile
            id="player"
            fanned="right"
            onClick={({ card }) => canDiscard && dispatch(selectCard(card))}
          />}
          mobile={<MyStyledPileMobile
            id="player"
            fanned="right"
            onClick={({ card }) => canDiscard && dispatch(selectCard(card))}
          />}
        />
      </GameWrapper>
      <NewGameModal dispatch={dispatch} state={state} />
      <EndGameModal dispatch={dispatch} state={state} />
    </React.Fragment>
  );
};

export default Game;
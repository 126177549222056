import {
  NEXT,
  FOLD,
  CHANGE_BET,
  TOGGLE_AI,
  START_TUTORIAL,
  NEXT_TUTORIAL_STEP,
} from './constants';

export const next = () => ({ type: NEXT });
export const fold = () => ({ type: FOLD });
export const toggleAi = () => ({ type: TOGGLE_AI });
export const startTutorial = () => ({ type: START_TUTORIAL });
export const nextTutorialStep = () => ({ type: NEXT_TUTORIAL_STEP });

export const changeBet = (bet, value) => ({
  type: CHANGE_BET,
  bet,
  value,
});
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { POST_QUERY, TAG_QUERY } from './constants';

export const useTagQuery = (opts) => useQuery(TAG_QUERY, opts);
export const usePostQuery = (opts) => useQuery(POST_QUERY, opts);

export const withTagsQuery = (Component) => (props) => {
  const tags = useTagQuery();
  return <Component {...props} tags={tags} />;
};

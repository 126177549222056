import { cards } from 'cards';
import { DRAW, DISCARD } from './constants';
import { EASY } from '../players';
import initialState from './initial-state';
import { score } from '../utils';

export const resetGame = (state) => ({
  ...initialState
});

export const newGame = (state) => ({
  ...state,
  newGameOpen: true,
});

const shuffleArray = (arr) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const ranksA = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
export const ranksB = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

const indexRanks = (cards, ranks) => {
  return cards.map((card) => ({
    ...card,
    rankIndex: ranks.indexOf(card.rank),
  }));
}

const sortRun = (a, b) => {
  if (a.suit !== b.suit) {
    return a.suit.localeCompare(b.suit);
  }
  return a.rank - b.rank;
};

export const getRuns = (cards, sortOrder, min = 3) => {
  const remaining = indexRanks(cards, sortOrder);
  const runs = [];
  
  remaining.sort(sortRun);

  for (let idx = 0; idx < (remaining.length - 1); idx += 1) {
    let c = 1;
    while((idx + c) < remaining.length) {
      if (remaining[idx].suit !== remaining[idx+c].suit) {
        break;
      }
      if (remaining[idx].rankIndex + c !== remaining[idx+c].rankIndex) {
        break;
      }
      c += 1;
    }
    if (c >= min) {
      const a = remaining.splice(idx, c);
      a.forEach(c => runs.push(c));
    }
  }

  return { remaining, runs };
};

export const sortHand = (cards) => {
  const byRank = indexRanks(cards, ranksA);
  const result = [];

  byRank.sort((a, b) => a.rankIndex - b.rankIndex);

  for (let idx = 0; idx < (byRank.length - 2); idx += 1) {
    const card = byRank[idx];
    if (card.rank === byRank[idx+1].rank) {
      if (card.rank === byRank[idx+2].rank) {
        result.push(card);
        result.push(byRank[idx+1]);
        result.push(byRank[idx+2]);
        if (idx < (byRank.length - 3)) {
          if (card.rank === byRank[idx+3].rank) {
            result.push(byRank[idx+3]);
            byRank.splice(idx+3, 1);
          }
        }
        byRank.splice(idx, 3);
        idx -= 1;
      }
    }
  }

  const { runs: runsA, remaining } = getRuns(byRank, ranksA);
  runsA.forEach(c => result.push(c));
  const { runs: runsB, remaining: remainingB } = getRuns(remaining, ranksB);
  runsB.forEach(c => result.push(c));
  remainingB.forEach(c => result.push(c));
  return result;
};

export const deal = (state, action) => {
  const shuffled = shuffleArray(cards);
  const piles = {
    stock: {
      cards: [],
      facedown: true,
    },
    discard: {
      cards: [],
    },
    player: {
      cards: [],
    },
    player2: {
      cards: [],
      facedown: true,
    },
  };
  const melds = {
    player: [],
    player2: [],
  };

  let i = 0;
  while (i < 7) {
    piles.player.cards.push(shuffled.shift());
    piles.player2.cards.push(shuffled.shift());
    i++;
  }
/*
  while (i < 23) {
    melds.player.push([shuffled[0]]);
    melds.player2.push([shuffled[0]]);
    i++;
  }
  /*
  piles.discard.cards = [
    { suit: 'S', rank: 'A' },
    { suit: 'H', rank: '2' },
    { suit: 'C', rank: 'A' },
    { suit: 'H', rank: '4' },
    { suit: 'D', rank: 'A' },
    { suit: 'H', rank: '3' },
    { suit: 'H', rank: 'A' },
  ];
  */

  piles.player.cards = sortHand(piles.player.cards);
  piles.player2.cards = sortHand(piles.player2.cards);

  piles.discard.cards.push(shuffled.shift());

  piles.stock.cards = shuffled;
  return {
    ...state,
    piles,
    turn: 'player',
    melds,
    opponent: action.opponent ? action.opponent : EASY,
    newGameOpen: false,
  };
};

export const draw = (state) => ({
  ...state,
  piles: {
    ...state.piles,
    [state.turn]: {
      ...state.piles[state.turn],
      cards: sortHand([
        ...state.piles[state.turn].cards,
        state.piles.stock.cards[0],
      ]),
    },
    stock: {
      ...state.piles.stock,
      cards: state.piles.stock.cards.slice(1),
    },
  },
  lastStep: DRAW,
});

export const discard = (state, { card }) => {
  const selectedCards = state.piles[state.turn].cards.filter(c => !!c.selected);
  if (selectedCards.length !== 1) {
    return {
      ...state,
      error: 'You must select 1 card to discard',
    };
  }
  const win = state.piles[state.turn].cards.length === 1 || state.piles.stock.cards.length === 0;
  const player = score(state.melds.player, state.piles.player.cards);
  const player2 = score(state.melds.player2, state.piles.player2.cards);
  const isWinner = win && player > player2;
  const isLoser = win && player2 > player;
  const isTie = win && player2 == player;
  const turn = win ? 'win' : (state.turn === 'player' ? 'player2' : 'player');
  return {
    ...state,
    piles: {
      ...state.piles,
      [state.turn]: {
        ...state.piles[state.turn],
        cards: state.piles[state.turn].cards.filter(c => !c.selected),
      },
      discard: {
        ...state.piles.discard,
        cards: [
          ...state.piles.discard.cards,
          {
            ...selectedCards[0],
            selected: false,
          },
        ],
      },
    },
    scores: {
      player,
      player2,
    },
    turn,
    lastStep: DISCARD,
    win,
    isWinner,
    isLoser,
    isTie,
  };
};

export const selectCard = (state, { card }) => ({
  ...state,
  piles: {
    ...state.piles,
    [state.turn]: {
      ...state.piles[state.turn],
      cards: state.piles[state.turn].cards.map((c) => {
        if (c.suit === card.suit && c.rank === card.rank) {
          return {
            ...c,
            selected: !c.selected,
          };
        }
        return c;
      }),
    },
  },
});

export const meld = (state) => {
  const player = state.turn;
  const selectedCards = state.piles[player].cards.filter(c => !!c.selected);
  
  const isSet = selectedCards.reduce(
    (acc, val) => acc && val.rank === selectedCards[0].rank,
    true,
  );
  
  if (!isSet) {
    const runsA = getRuns(selectedCards, ranksA);
    if (runsA.remaining.length > 0) {
      const runsB = getRuns(selectedCards, ranksB);
      if (runsB.remaining.length > 0) {
        return {
          ...state,
          error: 'Select one set or run at a time',
        };
      }
    }
  }

  return {
    ...state,
    piles: {
      ...state.piles,
      [player]: {
        ...state.piles[player],
        cards: state.piles[player].cards.filter(c => !c.selected),
      },
    },
    melds: {
      ...state.melds,
      [player]: [
        ...state.melds[player],
        selectedCards.map(({ selected, ...card }) => card),
      ],
    },
  };
};

export const drawFromDiscard = (state, { card }) => {
  const discardCards = state.piles.discard.cards;
  const player = state.turn;

  let found = -1;
  for(let idx = 0; idx < discardCards.length; idx++) {
    const val = discardCards[idx];
    if (val.suit === card.suit && val.rank === card.rank) {
      found = idx;
      break;
    }
  }
  if (found === -1) {
    return state;
  }

  const addedCards = discardCards.splice(found);

  return {
    ...state,
    piles: {
      ...state.piles,
      [player]: {
        ...state.piles[player],
        cards: sortHand([
          ...state.piles[player].cards,
          ...addedCards,
        ]),
      },
      discard: {
        cards: discardCards,
      },
    },
    lastStep: DRAW,
  };
};
import { DEAL, DRAW, DISCARD, SELECT_CARD, MELD, DRAW_FROM_DISCARD, NEW_GAME, RESET_GAME } from './constants';
import { deal, draw, discard, selectCard, meld, drawFromDiscard, newGame, resetGame } from './actions';

const actionLookup = {
  [DEAL]: deal,
  [DRAW]: draw,
  [DISCARD]: discard,
  [SELECT_CARD]: selectCard,
  [MELD]: meld,
  [DRAW_FROM_DISCARD]: drawFromDiscard,
  [NEW_GAME]: newGame,
  [RESET_GAME]: resetGame,
};

const reducer = (state, action) => {
  const { error, ...cleanState } = state;
  const actionFunc = actionLookup[action.type];
  if (typeof actionFunc === 'function') {
    const newState = actionFunc(cleanState, action);
    return newState;
  }
  return state;
};

export default reducer;
import { cards } from 'cards';
import { rows } from './initial-state';

const shuffleArray = (arr) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isWinner = (state) => {
  const { piles } = state;

  for(let i=0;i<rows.length;i++) {
    const row = rows[i];
    let prevCard = piles[row[0]].cards[0];
    if (prevCard.rank != 2) {
      return false;
    }
    for(let j=1;j<row.length-1;j++) {
      const thisCard = piles[row[j]].cards[0];
      if (thisCard.rank === nextRank[prevCard.rank] && thisCard.suit === prevCard.suit) {
        prevCard = thisCard;
      } else {
        return false;
      }
    }
    if (!piles[row[row.length-1]].facedown) {
      return false;
    }
  }

  return true;
}

export const isEndGame = (state) => {
  const { newGame, shuffleCount, piles } = state;
  if (newGame || shuffleCount < 3) {
    return false;
  }

  for(let i=0;i<rows.length;i++) {
    const row = rows[i];
    let prevCard = piles[row[0]].cards[0];
    if (prevCard.facedown) {
      return false;
    }
    let inKings = prevCard.rank === 'K';
    for(let j=1;j<row.length;j++) {
      const thisCard = piles[row[j]].cards[0];
      if (thisCard.facedown) {
        if (!inKings) {
          return false;
        }
      } else {
        inKings = thisCard.rank === 'K';
      }
    }
  }

  return true;

}

export const ranksA = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
export const ranksB = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

const nextRank = {
  '2': '3',
  '3': '4',
  '4': '5',
  '5': '6',
  '6': '7',
  '7': '8',
  '8': '9',
  '9': '10',
  '10': 'J',
  'J': 'Q',
  'Q': 'K'
};

const prevRank = {
  '4': '3',
  '5': '4',
  '6': '5',
  '7': '6',
  '8': '7',
  '9': '8',
  '10': '9',
  'J': '10',
  'Q': 'J',
  'K': 'Q',
  '3': '2'
};

export const deal = (state) => {
  const shuffled = shuffleArray(cards).map(card => {
    if (card.rank === 'A') {
      return {
        facedown: true,
      };
    }
    return card;
  });
  const piles = {};
  rows.forEach(row => {
    row.forEach(pileId => {
      piles[pileId] = { cards: [shuffled.pop()] };
    });
  });
  return {
    ...state,
    piles,
    newGame: false,
    shuffleCount: 0,
  }
};

const properPlaces = {
  '00': '2',
  '01': '3',
  '02': '4',
  '03': '5',
  '04': '6',
  '05': '7',
  '06': '8',
  '07': '9',
  '08': '10',
  '09': 'J',
  '10': 'Q',
  '11': 'K',
}

export const shuffle = (state) => {
  if (state.shuffleCount === 3) {
    return state;
  }
  const { piles } = state;

  const rowStart = [0, 0, 0, 0];
  const myCards = [];
  for(let i=0;i<rows.length;i++) {
    const row = rows[i];
    let prevCard = piles[row[0]].cards[0];
    let isGood = prevCard.rank == 2;
    if (!isGood) {
      myCards.push(prevCard);
    }
    for(let j=1;j<row.length;j++) {
      const thisCard = piles[row[j]].cards[0];
      if (isGood) {
        if (thisCard.rank === nextRank[prevCard.rank] && thisCard.suit === prevCard.suit) {
          prevCard = thisCard;
        } else {
          rowStart[i] = j;
          isGood = false;
        }
      }
      if (!isGood) {
        myCards.push(thisCard);
      }
    }
  }

  const shuffledCards = shuffleArray(myCards);
  for(let i=0;i<rows.length;i++) {
    const row = rows[i];
    for(let j=rowStart[i];j<row.length;j++) {
      piles[row[j]].cards = [shuffledCards.pop()];
    }
  }

  return {
    ...state,
    piles,
    shuffleCount: state.shuffleCount + 1
  };
};

export const click = (state, { pileId }) => {
  const pileKeys = Object.keys(state.piles);

  if (state.piles[pileId].cards[0].facedown) {
    let fromPileId = '';
    if (pileId.endsWith('00')) {
      // Find a 2
      for(let i=0;i<pileKeys.length;i++) {
        if (i % 13 === 0) {
          continue;
        }
        if (state.piles[pileKeys[i]].cards[0].rank === '2') {
          fromPileId = pileKeys[i];
          break;
        }
      }
    } else {
      const prevPileId = pileKeys[pileKeys.indexOf(pileId) - 1];
      const [{ rank, suit }] = state.piles[prevPileId].cards;
      if (!rank || rank === 'K') {
        return state;
      }
      // Find the matching card's pileId
      for(let i=0;i<pileKeys.length;i++) {
        const [card] = state.piles[pileKeys[i]].cards;
        if (card.suit === suit && card.rank === nextRank[rank]) {
          fromPileId = pileKeys[i];
          break;
        }
      }
    }
    // Swap pileId and fromPileId
    return {
      ...state,
      piles: {
        ...state.piles,
        [fromPileId]: state.piles[pileId],
        [pileId]: state.piles[fromPileId]
      },
    };
  }

  let prevPileId = '';
  let nextPileId = '';
  const [{ rank, suit }] = state.piles[pileId].cards;
  if (rank === '2') {
    // Is there an opening at the front?
    for(let i=0;i<4;i++) {
      if (state.piles[`${i}00`].cards[0].facedown) {
        nextPileId = `${i}00`;
      }
    }
    if (nextPileId === '') {
      return state;
    }
  } else {
    // Where is the previous rank card?
    for(let i=0;i<pileKeys.length-1;i++) {
      const [card] = state.piles[pileKeys[i]].cards;
      if (card.suit === suit && nextRank[card.rank] === rank) {
        prevPileId = pileKeys[i];
        break;
      }
    }
    if (prevPileId === '') {
      return state;
    }
    // Does a blank follow?
    nextPileId = pileKeys[pileKeys.indexOf(prevPileId) + 1];
    if (!state.piles[nextPileId].cards[0].facedown) {
      return state;
    }
  }
  // Swap pileId and nextPileId
  return {
    ...state,
    piles: {
      ...state.piles,
      [nextPileId]: state.piles[pileId],
      [pileId]: state.piles[nextPileId]
    },
  };
};
import React, { useState } from 'react';
import { usePostQuery } from '../apollo/query';
import Content from './Content';
import MultiContent from './MultiContent';

const ContentQuery = (props) => {
  const limit = 5;
  const [ hasMore, setHasMore ] = useState(true);
  const contentInfo = usePostQuery({
    variables: {
      ...props,
      limit,
    },
  });
  const { loading, error, data, fetchMore } = contentInfo;
  if (loading) {
    // TODO: Better loading
    return 'Loading...';
  }
  if (error) {
    // TODO: Better error handling
    return 'There was a problem';
  }
  const content = data.getContent;
  if (content.length === 1) {
    return (
      <Content {...content[0]} />
    );
  } else if (content.length === 0) {
    // TODO: Better 404 page
    return 'Oops, nothing was found';
  }
  return (
    <MultiContent
      content={content}
      hasMore={hasMore}
      loadMore={() =>
        fetchMore({
          variables: {
            offset: content.length,
          },
          updateQuery: (previous, { fetchMoreResult }) => {
            if (!fetchMoreResult || !fetchMoreResult.getContent.length) {
              setHasMore(false);
              return previous;
            }
            return {
              ...previous,
              getContent: [
                ...previous.getContent,
                ...fetchMoreResult.getContent,
              ],
            };
          },
        })
      }
    />
  );
};

export default ContentQuery;

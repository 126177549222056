import React from 'react';
import styled from 'styled-components';

const WinLoseOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const WinLoseH1 = styled.h1`
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
`;

const Lose = ({ className, score }) => (
  <WinLoseOverlay className={className}>
    <WinLoseH1>
      You Lost!
      {score && ` Score: ${score}`}
    </WinLoseH1>
  </WinLoseOverlay>
);

export default Lose;
import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="text-center text-light bg-dark p-1 p-lg-2">
      <small>
        Copyright &copy; {year} Barry Gilbert.
      </small>
    </div>
  );
};

export default Footer;

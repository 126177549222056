import React from 'react';
import styled from 'styled-components';
import { changeBet } from './reducer/action-creators';
import { START, DEAL } from './reducer/constants';
import { BET_PLAY } from './constants';
import {
  OverlayHole,
  OverlayHoleWrapper
} from '../../components/Styled';

const CenteredRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledBet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bet = ({ bet, state, dispatch, showOverlay }) => (
  <OverlayHoleWrapper>
    <CenteredRow>
      <h4>
        {`${bet.charAt(0).toUpperCase()}${bet.substr(1).toLowerCase()}`}
        {state.player.winnings && ` Won: ${state.player.winnings[bet] || 0}`}
      </h4>
    </CenteredRow>
    <CenteredRow>
      <button
        className="btn btn-sm btn-secondary m-1"
        onClick={() => dispatch(changeBet(bet, -5))}
        disabled={!state.player.bets[bet] || state.player.bets[bet] < 5 || (bet === BET_PLAY && state.step !== DEAL) || (bet !== BET_PLAY && state.step !== START)}
      >
        -5
      </button>
      <button
        className="btn btn-sm btn-secondary m-1"
        onClick={() => dispatch(changeBet(bet, -1))}
        disabled={!state.player.bets[bet] || state.player.bets[bet] < 1 || (bet === BET_PLAY && state.step !== DEAL) || (bet !== BET_PLAY && state.step !== START)}
      >
       -1 
      </button>
      <StyledBet>{state.player.bets[bet] || 0}</StyledBet>
      <button
        className="btn btn-sm btn-secondary m-1"
        onClick={() => dispatch(changeBet(bet, 1))}
        disabled={(bet === BET_PLAY && state.step !== DEAL) || (bet !== BET_PLAY && state.step !== START)}
      >
        +1
      </button>
      <button
        className="btn btn-sm btn-secondary m-1"
        onClick={() => dispatch(changeBet(bet, 5))}
        disabled={(bet === BET_PLAY && state.step !== DEAL) || (bet !== BET_PLAY && state.step !== START)}
      >
        +5
      </button>
    </CenteredRow>
    {showOverlay && <OverlayHole />}
  </OverlayHoleWrapper>
);

export default Bet;
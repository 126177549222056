import React from 'react';
import { Navbar as RbNavbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <RbNavbar bg="light" expand="lg">
    <RbNavbar.Brand to="/" as={Link}>Barry Web Dev</RbNavbar.Brand>
    <RbNavbar.Toggle aria-controls="basic-navbar-nav" />
    <RbNavbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        <NavDropdown title="Games">
          <NavDropdown.Item as={Link} to="/games/freecell">Freecell</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/klondike">Klondike</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/addiction">Addiction</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/blackjack">Blackjack</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/poker">3 Card Poker</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/rummy">Rummy</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/games/slider">Slider</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="https://www.nonogramsonline.com">Nonograms</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to="/category/Tips/">Tips</Nav.Link>
        <Nav.Link as={Link} to="/category/Programming/">Programming</Nav.Link>
        <Nav.Link as={Link} to="/category/Web/">Web</Nav.Link>
        <Nav.Link as={Link} to="/category/Linux/">Linux</Nav.Link>
        <Nav.Link as={Link} to="/category/Networks/">Networks</Nav.Link>
        <Nav.Link as={Link} to="/category/Windows/">Windows</Nav.Link>
        <Nav.Link as={Link} to="/category/Repairs/">Repairs</Nav.Link>
        <Nav.Link href="https://www.github.com/barrygilbert/">Github</Nav.Link>
        <Nav.Link href="https://www.linkedin.com/in/jamesbgilbert">LinkedIn</Nav.Link>
        <Nav.Link as={Link} to="/about-me/">About Me</Nav.Link>
      </Nav>
    </RbNavbar.Collapse>
  </RbNavbar>
);

export default Navbar;

import React from 'react';
import styled from 'styled-components';

const OverlayHoleWrapper = styled.div`
  position: relative;
  ${({ show }) => show && `
    z-index: 11;
    backdrop-filter: brightness(2);
  `}
`;

export default OverlayHoleWrapper;
import React from 'react';
import { Pile } from 'cards';
import styled from 'styled-components';
import { deal, flip, click } from './reducer/action-creators';
import { GameWrapper, Win } from '../../components/Styled';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 768px) {
    grid-gap: 12px;
  }
`;

const CasecadeRowWrapper = styled(RowWrapper)`
  flex-grow: 1;
  margin-top: 20px !important;
  position: relative;
`;

const ButtonWrapper = styled.div``;

const StyledInnerCascade = styled(Pile)`
  & > div:not(:last-child) {
    height: 30px;
    @media (max-width: 600px) {
      height: 25px;
    }
    @media (max-width: 550px) {
      height: 23px;
    }
    @media (max-width: 450px) {
      height: 19px;
    }
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Desktop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledPile = (props) => (
  <div>
    <Mobile><Pile {...props} width="100%" /></Mobile>
    <Desktop><Pile {...props} width="75px" /></Desktop>
  </div>
);

const StyledCascade = (props) => (
  <div>
    <Mobile><StyledInnerCascade {...props} width="100%" /></Mobile>
    <Desktop><StyledInnerCascade {...props} width="75px" /></Desktop>
  </div>
)

const Game = ({ state, dispatch }) => (
  <GameWrapper>
    <RowWrapper>
      <StyledPile id="deck" onClick={() => dispatch(click('deck'))} />
      <StyledPile id="discard" onClick={() => dispatch(click('discard'))} />
      <div />
      <StyledPile id="foundation_s" />
      <StyledPile id="foundation_h" />
      <StyledPile id="foundation_c" />
      <StyledPile id="foundation_d" />
    </RowWrapper>
    <CasecadeRowWrapper>
      {state.winner ?
        (<Win />)
      :
        ([1,2,3,4,5,6,7].map(id => (
          <StyledCascade id={`cascade_${id}`} fanned="down" onClick={() => dispatch(click(`cascade_${id}`))} />
        )))
      }
    </CasecadeRowWrapper>
    <ButtonWrapper>
      <button className="btn btn-info" onClick={() => dispatch(deal())}>New Game</button>
    </ButtonWrapper>
  </GameWrapper>
);

export default Game;
const initialState = {
  newGame: true,
  piles: {},
};

export const rows = [];

const twoDigit = (n) => {
  if ( n > 9 ) {
    return n;
  }
  return `0${n}`;
}

for(let i=0;i<4;i++) {
  let thisRow = [];
  for(let j=0;j<13;j++) {
    thisRow.push(`${i}${twoDigit(j)}`);
  }
  rows.push(thisRow);
}

export default initialState;
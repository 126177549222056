import { cards } from 'cards';
import initialState from './initial-state';
import {
  BET_ANTE,
  BET_PLAY,
  BET_PAIR,
} from '../constants';
import scoreHand from './scoring';

export const startTutorial = (state) => ({
  ...state,
  tutorialStep: 'WELCOME',
});

const tutorialSteps = {
  'WELCOME': 'BETS',
  'BETS': 'ANTE',
  'ANTE': 'PAIR',
  'PAIR': 'BUTTONS',
  'BUTTONS': 'PLAYER',
  'PLAYER': 'PLAY',
  'PLAY': 'DEALER',
  'DEALER': 'AI1',
  'AI1': 'FINISH',
  'FINISH': 'STOP',
}

export const nextTutorialStep = (state) => ({
  ...state,
  tutorialStep: tutorialSteps[state.tutorialStep],
})

const minAiScore = scoreHand([
  { suit: 'H', rank: 'Q' },
  { suit: 'S', rank: '6' },
  { suit: 'D', rank: '4' },
]);

const shuffleArray = (arr) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const minDealerScore = scoreHand([
  { suit: 'H', rank: 'Q' },
  { suit: 'S', rank: '2' },
  { suit: 'D', rank: '3' },
]);


export const toggleAi = (state) => ({
  ...state,
  showAiPlayers: !state.showAiPlayers,
});


export const deal = (state) => {
  const shuffled = shuffleArray(cards);
  const {
    winner,
    loser,
    winnings,
    ...cleanState
  } = state;
  const piles = {};
  let idx = 0;
  const dealOrder = ['dealer', 'ai2', 'player', 'ai1'];
  dealOrder.forEach((pile) => {
    piles[pile] = {
      cards: [],
      facedown: true,
    };
  });
  delete piles.player.facedown;
  [0,1,2].forEach(() => dealOrder.forEach((pile) => {
    piles[pile].cards.push(shuffled[idx]);
    idx++;
  }));
  return {
    ...cleanState,
    ai: {
      1: {
        bets: {
          [BET_ANTE]: 5,
          [BET_PAIR]: 5,
        },
        chips: cleanState.ai[1].chips,
      },
      2: {
        bets: {
          [BET_ANTE]: 5,
          [BET_PAIR]: 5,
        },
        chips: cleanState.ai[2].chips,
      },
    },
    piles,
  };
};

export const reveal = (state) => {
  const piles = {};
  Object.keys(state.piles).forEach((pile) => {
    piles[pile] = {
      ...state.piles[pile],
      facedown: false,
    };
  });

  const ai1Plays = scoreHand(piles['ai1'].cards) > minAiScore;
  const ai2Plays = scoreHand(piles['ai2'].cards) > minAiScore;

  return {
    ...state,
    ai: {
      1: {
        ...state.ai[1],
        bets: {
          ...state.ai[1].bets,
          [BET_PLAY]: ai1Plays ? state.ai[1].bets[BET_ANTE] : 0,
        },
      },
      2: {
        ...state.ai[2],
        bets: {
          ...state.ai[2].bets,
          [BET_PLAY]: ai2Plays ? state.ai[2].bets[BET_ANTE] : 0,
        },
      },
    },
    piles,
  };
};

export const score = (state) => {
  const { piles } = state;
  const dealerCards = piles.dealer.cards;
  const playerWinnings = scoreAHand(dealerCards, piles.player.cards, state.player.bets);
  const ai1Winnings = scoreAHand(dealerCards, piles.ai1.cards, state.ai[1].bets);
  const ai2Winnings = scoreAHand(dealerCards, piles.ai2.cards, state.ai[2].bets);
  return {
    ...state,
    player: {
      ...state.player,
      ...playerWinnings,
    },
    ai: {
      1: {
        ...state.ai[1],
        ...ai1Winnings,
      },
      2: {
        ...state.ai[2],
        ...ai2Winnings,
      },
    },
  };
};

const scoreAHand = (dealerCards, playerCards, bets) => {
  if (!bets[BET_PLAY]) {
    return {
      winnings: {
        [BET_ANTE]: 0,
        [BET_PLAY]: 0,
        [BET_PAIR]: 0,
      },
      loser: true,
    };
  }
  const playerScore = scoreHand(playerCards);
  const dealerScore = scoreHand(dealerCards);
  const dealerQualifies = dealerScore >= minDealerScore;
  const winner = !dealerQualifies || playerScore >= dealerScore;
  return {
    winnings: {
      [BET_ANTE]: (
        (winner ? bets[BET_ANTE] : 0) + 
        (playerScore >= 12288 ? bets[BET_ANTE] : 0) +
        (playerScore >= 16384 ? bets[BET_ANTE] * 7 : 0) +
        (playerScore >= 20480 ? bets[BET_ANTE] : 0)
       ),
      [BET_PLAY]: (winner && dealerQualifies) ? bets[BET_PLAY] : 0,
      [BET_PAIR]: (
        (playerScore >= 4096 ? bets[BET_PAIR] : 0) +
        (playerScore >= 8192 ? bets[BET_PAIR] * 3 : 0) +
        (playerScore >= 12288 ? bets[BET_PAIR] * 2 : 0) +
        (playerScore >= 16384 ? bets[BET_PAIR] * 26 : 0) +
        (playerScore >= 20480 ? bets[BET_PAIR] * 8 : 0)
      )
    },
    winner,
    loser: !winner,
  };
};

export const nextGame = (state) => ({
  ...initialState,
  player: {
    bets: {},
    chips:
      state.player.winner
        ?
          (state.player.chips + valSum(state.player.winnings))
        :
          (state.player.chips - valSum(state.player.bets)),
  },
  ai: {
    1: {
      bets: {},
      chips:
        state.ai[1].winner
          ?
            (state.ai[1].chips + valSum(state.ai[1].winnings))
          :
            (state.ai[1].chips - valSum(state.ai[1].bets)),
    },
    2: {
      bets: {},
      chips:
        state.ai[2].winner
          ?
            (state.ai[2].chips + valSum(state.ai[2].winnings))
          :
            (state.ai[2].chips - valSum(state.ai[2].bets)),
    },
  },
});

const valSum = (obj) => Object.values(obj).reduce(((acc, val) => acc + val), 0);

export const changeBet = (state, { bet, value, aiId }) => {
  if (!aiId) {
    const { player } = state;
    return {
      ...state,
      player: {
        ...player,
        bets: {
          ...player.bets,
          [bet]: (player.bets[bet] ? player.bets[bet] : 0) + value,
        },
      },
    };
  }

  const ai = state.ai[aiId];

  return {
    ...state,
    ai: {
      ...state.ai,
      [aiId]: {
        ...ai,
        bets: {
          ...ai.bets,
          [bet]: (ai.bets[bet] ? ai.bets[bet] : 0) + value,
        },
      },
    },
  };
};

export const fold = (state) => ({
  ...initialState,
  player: {
    bets: {},
    chips: state.player.chips - valSum(state.player.bets),
  },
});
import React from 'react';
import PropTypes from 'prop-types';
import OgHelmet from './OgHelmet';

const OgDesc = ({ content }) => {
  return (
    <OgHelmet>
      <meta name="description" content={content} />
    </OgHelmet>
  );
};

OgDesc.propTypes = {
  content: PropTypes.object,
};

export default OgDesc;


const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];

const indexRanks = (cards) => {
  return cards.map((card) => ({
    ...card,
    rank: ranks.indexOf(card.rank),
  }));
}

const sortHand = (cards) => {
  return [...cards].sort((a, b) => (a.rank - b.rank));
};

const scoreHand = (cards) => {
  const sortedHand = sortHand(indexRanks(cards));
  let score = 0;

  if (sortedHand.reduce((acc, card) => (acc && card.rank === cards[0].rank), true)) {
    score += 16384; //0x4000
  } else if (sortedHand[0].rank === sortedHand[1].rank) {
    score += 4096; //0x1000
    // Flip so pair is at the end (see next section)
    const t = sortedHand[0];
    sortedHand[0] = sortedHand[2];
    sortedHand[2] = t;
  } else if (sortedHand[1].rank === sortedHand[2].rank) {
    score += 4096; //0x1000
  } else {
    if (sortedHand[0].rank + 1 === sortedHand[1].rank && sortedHand[1].rank + 1 === sortedHand[2].rank) {
      score += 12288; //0x3000
    }
    if (sortedHand[0].rank === 0 && sortedHand[1].rank === 1 && sortedHand[2].rank === 12) {
      score += 12288; //0x3000
      // Ace is 12, so this is a valid straight, but the zero needs to move to the bottom rank
      const { suit } = sortedHand[2];
      sortedHand[2] = sortedHand[1];
      sortedHand[1] = sortedHand[0];
      sortedHand[0] = {
        suit,
        rank: -1,
      }
    }
    if (cards.reduce((acc, card) => (acc && card.suit === cards[0].suit), true)) {
      score += 8192; //0x2000
    }
  }

  // Higher is better!
  score += sortedHand[0].rank + sortedHand[1].rank * 16 + sortedHand[2].rank * 256;

  return score;
};

export default scoreHand;

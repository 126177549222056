import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OgHelmet from './OgHelmet';
import { IS_DEV, AD_TEST } from '../config';

const insStyle = {
  width: '100%',
  display: 'block',
};

class MyAd extends Component {
  componentDidMount() {
    if(window && (!IS_DEV || AD_TEST)) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
  render() {
    const { client, slot } = this.props;
    return (
      <>
        <OgHelmet>
          <script
            async
            src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          />
        </OgHelmet>
        <ins
          className="adsbygoogle"
          data-ad-client={client}
          data-ad-slot={slot}
          data-ad-format="auto"
          data-adtest={IS_DEV && AD_TEST && 'on'}
          data-full-width-responsive="true"
          style={insStyle}
        />
      </>
    );
  }
};
MyAd.propTypes = {
  slot: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
};

export default MyAd;

import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledNav = styled.nav`
  background: #095e6b;
`;


export default class NavMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { className } = this.props;
    const { isOpen } = this.state;
    return (
      <StyledNav className={`navbar navbar-expand-lg navbar-dark ${className}`}>
        <a className="navbar-brand" href="/">
          BarryWebDev.com
        </a>
        <button type="button" className="navbar-toggler" onClick={this.toggle}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={classnames(
            'collapse navbar-collapse',
            isOpen ? ' show' : ''
          )}
        >
          <ul className="ml-auto navbar-nav">
          <li className="nav-item">
              <a className="nav-link"  href="/klondike">
                Klondike
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/addiction">
                Addiction
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/blackjack">
                Black Jack
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/poker">
                Poker
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/rummy">
                Rummy
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/freecell">
                Freecell
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="/slider">
                Slider Puzzle
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  href="https://nonogramsonline.com/">
                Nonograms Online.com
              </a>
            </li>
          </ul>
        </div>
      </StyledNav>
    );
  }
}

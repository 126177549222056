import React from 'react';

const Home = () => (
  <div>
    <div>Welcome to BarryWebDev.com. Here you'll find the games created by me, Barry Gilbert.</div>
    <div>Follow my programming journey on Twitter <a href="https://twitter.com/barrywebdev">@BarryWebDev</a>.</div>
    <div className="mt-3 mb-3">You can also checkout my blog at <a href="https://www.compguyaug.com/">https://www.compguyaug.com</a></div>
    <div className="mt-3 mb-3">I hope you enjoy!</div>
    <ul>
      <li><a href="/games/klondike">Klondike Solitaire</a></li>
      <li><a href="/games/addiction">Addiction Solitaire</a></li>
      <li><a href="/games/blackjack">Blackjack</a></li>
      <li><a href="/games/poker">3 Card Poker</a></li>
      <li><a href="/games/rummy">Rummy</a></li>
      <li><a href="/games/freecell">Freecell</a></li>
      <li><a href="/games/slider">Slider Puzzle</a></li>
    </ul>
  </div>
);

export default Home;

import React from 'react';
import PropTypes from 'prop-types';
import OgHelmet from './OgHelmet';

const OgTitle = ({ title, message }) => {
  return (
    <OgHelmet>
      <title>{title}{message ? ` - ${message}` : ''}</title>
    </OgHelmet>
  );
};

OgTitle.propTypes = {
  title: PropTypes.string,
  message: PropTypes.object,
};

OgTitle.defaultProps = {
  title: 'Barry Web Dev',
};

export default OgTitle;

import React from 'react';
import { Pile } from 'cards';
import styled from 'styled-components';
import {
  BET_TYPES,
} from './constants';
import { OverlayHole, OverlayHoleWrapper } from '../../components/Styled';

const Wrapper = styled(OverlayHoleWrapper)`
  display: flex;
`;

export const SmallerPile = styled(Pile)`
  & > div:not(:last-child) {
    width: 50px;
  }
`;

const AIPlayer = ({ id, state, showOverlay, className }) => (
  <Wrapper className={className}>
    <div className="mr-4">
      {BET_TYPES.map((type) => (
        <div key={`AIBET${id}${type}`}>
          {`${type.charAt(0).toUpperCase()}${type.substr(1).toLowerCase()}`}:
          {state.ai[id].bets[type]}
          <br />
          {state.ai[id].winnings ? (state.ai[id].winnings[type] > 0 ? 'Win' : 'Lose') : (<br />)}
        </div>
      ))}
    </div>
    <SmallerPile id={`ai${id}`} width="75px" fanned="hand" />
    {showOverlay && <OverlayHole />}
  </Wrapper>
);

export default AIPlayer;
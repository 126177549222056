import React from 'react';
import styled from 'styled-components';
import { Pile } from 'cards';
import { DEAL, HIT, START, STAY } from './reducer/constants';
import Lose from '../../components/Styled/Lose';
import Win from '../../components/Styled/Win';

const StyledWrapper = styled.div`
  min-width: 800px;
  min-height: 640px;
  max-width: 1024px;
  max-height: 90vh;
  display: grid;
  background: #477148;
  padding: 15px;

  @media (max-width: 1024px) {
    min-width: 90vw;
  }
`;

const PileWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const PlayerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
`;

const Winner = styled(Win)`
  position: relative;
  color: black;
`;

const Loser = styled(Lose)`
  position: relative;
  color: red;
`;

const BlackJackGame = ({ state, dispatch }) => {
  const { step, score, winner, loser } = state;
  return (
    <StyledWrapper>
      <div>
        <h2>Dealer</h2>
        <Pile
          width="130px"
          id="dealer"
          fanned="hand"
        />
      </div>
      <PlayerWrapper>
        <h2>Player</h2>
        <PileWrapper>
          <Pile
            width="130px"
            id="player"
            fanned="hand"
          />
          {winner && <Winner score={score} winner />}
          {loser && <Loser score={score} />}
        </PileWrapper>
        <div>
          <button
            className="btn btn-info mr-4"
            onClick={() => dispatch({ type: START })}
          >
            New Game
          </button>
          <button
            className="btn btn-info mr-4"
            onClick={() => dispatch({ type: DEAL })}
            disabled={step !== START && step !== STAY}
          >
            Deal
          </button>
          <button
            className="btn btn-info mr-4"
            onClick={() => dispatch({ type: HIT })}
            disabled={step === START || step === STAY}
          >
            Hit
          </button>
          <button
            className="btn btn-info"
            onClick={() => dispatch({ type: STAY })}
            disabled={step === START || step === STAY}
          >
            Stay
          </button>
        </div>
      </PlayerWrapper>
    </StyledWrapper>
  );
};

export default BlackJackGame;
import React from 'react';
import { PlayingCards } from 'cards';
import initialState from './reducer/initial-state';
import reducer from './reducer/reducer';
import Game from './Game';

const initialStateCopy = Object.assign({}, initialState);

const Addiction = () => (
  <PlayingCards
    reducer={reducer}
    initialState={initialStateCopy}
  >
    {(state, dispatch) => (
      <Game state={state} dispatch={dispatch} />
    )}
  </PlayingCards>
);

export default Addiction;
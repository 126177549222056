export const DEAL = 'DEAL';
export const REVEAL = 'REVEAL';
export const SCORE = 'SCORE';
export const NEXT = 'NEXT';
export const START = 'START';
export const FOLD = 'FOLD';
export const TOGGLE_AI = 'TOGGLE_AI';
export const START_TUTORIAL = 'START_TUTORIAL';
export const NEXT_TUTORIAL_STEP = 'NEXT_TUTORIAL_STEP';

export const CHANGE_BET = 'CHANGE_BET';

export const NEXT_STEP = {
  [DEAL]: 'Play',
  [REVEAL]: 'Score',
  [SCORE]: 'New Game',
  [START]: 'Deal',
};
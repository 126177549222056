import React from 'react';
import styled from 'styled-components';
import { nextTutorialStep } from './reducer/action-creators';

const Wrapper = styled.div`
  padding: 16px;
  z-index: 11;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const TrueCentered = styled.div`
  display: flex;
  justify-items: flex-center;
  flex-flow: column;
  > * {
    margin: 0 auto;
  }

`;

const tutorialText = {
  WELCOME: 'Hi! Welcome to the tutorial on 3 card poker!!! Here you will learn the basics of the game.',
  DEALER: 'This is the dealer\'s hand. This only shows at the end of the round',
  AI1: 'This is an AI player. Turn these on and off using the button in the top right',
  PAIR: 'This is a special bet. You can win with a pair and other configurations in your hand.',
  PLAY: 'This is the play bet. After receiving your cards, if you wish to continue you must place this bet.',
  ANTE: 'This is the ante bet. It is required before you can receive cards.',
  BETS: 'The betting area is here.',
  PLAYER: 'Your cards appear here.',
  BUTTONS: 'Here you will find the main buttons for the game and feedback',
  FINISH: 'You now know how to play. Enjoy the game!',
};

const TutorialText = ({ step, dispatch }) => {
  return (
    <Wrapper>
      <div>{tutorialText[step]}</div>
      <TrueCentered>
        <div>
          <button className="btn btn-info mt-4" onClick={() => dispatch(nextTutorialStep())}>Next Step</button>
        </div>
      </TrueCentered>
    </Wrapper>
  );
};

export default TutorialText;